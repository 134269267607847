@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap");

body {
  margin: 0;
  font-family: "Noto Sans JP", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
